const suggestList = [
    {
        id:1,
        category: 'Autonomy',
        suggestion: ["Asking questions when you disagree with the group rather than confronting them directly.", "Advocate for using your flexible working time. Practice what you want to say in your head or in writing. Practice can help you feel confident speaking up in the moment.", "Take ownership of your ongoing tasks. While you may not be able to control your assigned tasks, you can own the tasks you’re given. If you take responsibility for your tasks and structure them in ways that are best for you, you can make changes as you see fit.", "Talk to your manager about challenges and growth areas. You can set up a one-on-one with your manager after you’ve thought about the areas you want to grow and have a conversation with them about it. You can decide on a plan of action together. This will both increase the opportunity for you to give input on how you do your job and demonstrate initiative."]
    },
    {
        id:2,
        category:'Growth',
        suggestion: ["Develop 3 possible futures you would be happy with within your career path. Identify the skills you want to develop to make your future a reality. Discuss with your supervisor what skills will help you grow in your career and if there are any ways you can try to bring them into the team.",
        "Expand your network with conferences or professional associations for your industry to learn valuable insights.",
        "Develop interpersonal relationships or find a mentor to guide you through career experiences.",
        "Job shadow colleagues to learn about your organization and broaden your knowledge of skills you can develop to add value."]
    },
    {
        id:3,
        category:'Competence',
        suggestion: ["Set realistic expectations for yourself. Some feelings of incompetence stem from unrealistic expectations of how much a person can get done in a day. If this is your situation, boundaries with your workplace are important.",
        "Use time blocking to help you focus on tasks. If you have dedicated hours set for every project, if something unexpected comes up, you can tell your manager exactly what won’t get done if you prioritize the latest firefight.",
        "Participate in new projects that get you out of your comfort zone.",
        "Pursue on the job learning and training to help build up your skill in areas you feel you could improve on."]
    },
    {
        id:4,
        category:'Relationships',
        suggestion: ["Focusing on active listening. When you show dedicated attention on a person, you increase feelings of trust.",
        "Work on your people skills and effective open communication. Ask questions and get to know your colleagues.",
        "Offer assistance when you are aware of a need. Or ask if there’s anything you can do to support your colleagues.","Keep your commitments. You can build trust by showing your colleagues that they can rely on you. If you find it difficult to keep your commitments, it’s better to be open and honest rather than flaking on a commitment.","Hone your emotional intelligence by developing self-awareness, self-regulation, empathy for others, and conflict resolution."]
    },
    {
        id:5,
        category:'Purpose',
        suggestion: ["Negotiating with your manager for a few hours a week on a passion project.",
        "Volunteering a few hours a week on a passion project.",
        "Developing a 5-year plan.",
        "Implement goal-setting theory in your workgroup."]
    },
    {
        id:6,
        category:'Self-Acceptance',
        suggestion: ["Self-reflect on how you can improve your attitude: Why do you have your current work attitude? Has your self-acceptance at work always been this way? What can you do or what do you need to have the work self-acceptance you desire?",
        "Keep track of your wins and losses on a weekly or monthly basis. This information can show you patterns of what is happening.",
        "Don’t be afraid to speak up and share your thoughts in meetings. You have a personal stake in your work and projects; your input matters.",
        "Make a list of things or projects you want to attempt at work that excites you. It can be anything that you would be proud of leaving behind as your legacy when you either leave this job or organization. If you can’t think of anything, research or talk to others in your industry about interesting features, proposals, or activities they have done that you can implement."]
    }
]

export default suggestList;