import React, { useState } from "react";
import "./expandableSection.css";

const ExpandableSection = ({ title, children, defaultOpen}) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  return (
    <div className="expandable-section">
      <div
        className="section-header"
        onClick={() => setIsOpen(!isOpen)}
        style={{
          backgroundColor: "#fbfaf6",
          color: "#227788",
          display: "flex",
          alignItems: "center",
          padding: "1rem",
          cursor: "pointer",
          borderRadius: "0.2rem"
        }}
      >
        <span
          style={{
            marginRight: "1rem",
            transform: isOpen ? "rotate(90deg)" : "",
            transition: "transform 0.3s",
            display: "inline-block",
          }}
        >
          ▶
        </span>

        {title}
      </div>
      {isOpen && (
        <div className="section-content" style={{ backgroundColor: "#fbfaf6" }}>
          {children}
        </div>
      )}
    </div>
  );
};

export default ExpandableSection;
