import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCAp1DfvqD5wYMTlwr8Hc6TYoNgm-phuZI",
  authDomain: "info-capstone-5ed7e.firebaseapp.com",
  projectId: "info-capstone-5ed7e",
  storageBucket: "info-capstone-5ed7e.appspot.com",
  messagingSenderId: "951882527168",
  appId: "1:951882527168:web:b9b53a92cbe76b58d45825"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { auth };