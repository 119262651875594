const questionList = [
    { id: 1, text: 'At work, I am free to decide how I go about completing a task', category: 'Work Autonomy'},
    { id: 2, text: 'I can decide how I accomplish my work', category: 'Work Autonomy'},
    { id: 3, text: 'I have freedom to make decisions about things that impact my work', category: 'Work Autonomy'},
    { id: 4, text: 'I feel capable and effective at work', category: 'Work Competence'},
    { id: 5, text: 'I have the skills needed to succeed at work', category: 'Work Competence'},
    { id: 6, text: 'I feel confident in my ability to achieve my goals at work', category: 'Work Competence'},
    { id: 7, text: 'I have an opportunity to grow many of my skills and talents at work', category: 'Work Growth'},
    { id: 8, text: 'My work provides me with the opportunity to learn constantly', category: 'Work Growth'},
    { id: 9, text: 'I feel confident in my ability to achieve my goals at work', category: 'Work Growth'},
    { id: 10, text: 'My work challenges me to learn and grow', category: 'Work Purpose' },
    { id: 11, text: 'My work seems important in the grand scheme of things', category: 'Work Purpose'},
    { id: 12, text: 'My work will likely positively impact the lives of others', category: 'Work Purpose'},
    { id: 13, text: 'I have a purposeful and meaningful job', category: 'Work Relationships'},
    { id: 14, text: 'I feel supported by the people I work with', category: 'Work Relationships'},
    { id: 15, text: 'I get along with people at work', category: 'Work Relationships'},
    { id: 16, text: 'I like who I am when I\'m at work' , category: 'Work Self-Acceptance'},
    { id: 17, text: 'I am still a valued member of my workplace even if I have an off day', category: 'Work Self-Acceptance'},
    { id: 18, text: 'I rarely question my worth at work', category: 'Work Self-Acceptance'},
  ];
  
  export default questionList;