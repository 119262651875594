// The MailList component is a reusable, functional React component that displays a mailing list subscription form.
// Users can input their email address and click the "Subscribe" button to subscribe to the mailing list.
// Upon successful subscription, a popup message will appear, and users can return to the main content by clicking the "Back to Survey" button.

// Import necessary modules and styles
import React, { useState } from "react";
import "./mailList.css";

// MailList component definition
const MailList = ({ onBackClick }) => {
  // Define local state for showing popup
  const [showPopup, setShowPopup] = useState(false);

  // Event handler for form submission
  const handleSubmit = () => {
    setShowPopup(true);
  };

  // Event handler for closing the popup
  const handleClosePopup = () => {
    setShowPopup(false);
    onBackClick();
  };

  // Return the component JSX structure
  return (
    <div className="mail">
      <div className="mailContainer">
        <h1 className="mailTitle">Stay Ahead of the Thrive!</h1>
        <span className="mailDesc">
          Unlock Your Workplace Potential with Our Subscription
        </span>
        <div className="mailInputContainer">
          <input type="text" placeholder="Your Email" />
          <button onClick={handleSubmit}>Subscribe</button>
        </div>
      </div>
      {showPopup && (
        <div className="popup-overlay">
          <div className="popup-content">
            <h2> You have successfully subscribed! </h2>
            <button
              className="back-to-results-button"
              onClick={handleClosePopup}
            >
              Back to Survey
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default MailList;