const resultList = [
    {id: 1, text: 'You scored high in the work autonomy area. You likely feel free to think independently and take initiative. You are able to resist social pressure and do work in ways that are best for you. In most situations, you feel confident in your opinion and comfortable expressing it when you’re on the job. You are not much worried about what others think about what you’re doing.', 
    category: 'Autonomy', grade: "High"
    },
    {id: 2, text: 'You scored moderate in the work autonomy area. In some situations, you’re able to think independently and take initiative. You can work in ways that work best for you, but sometimes your coworkers opinions influence how you do your work in ways that may not always work to your benefit. You feel somewhat uncomfortable comfortable expressing your opinion, especially when others don’t share the same beliefs.', 
    category: 'Autonomy', grade: "Moderate"
    },
    {id: 3, text: 'You scored low in the work autonomy area. In your work, you rarely take or have the opportunity to take initiative. At work, you tend to rely on the judgment of others in your decision-making. When it comes to group settings, you are most uncomfortable expressing your opinions. When you have opinions that are contrary to what most everyone else believes, you often cave to social pressures rather than disagreeing with the collective.', 
    category: 'Autonomy', grade: "Low"
    },
    {id: 4, text: 'You scored high in the work growth area. You see yourself as growing in your work and are open to new experiences in your current role. You are constantly looking for new ways of doing things or for   new responsibilities to take on at work. Your work provides you with opportunities to develop both personally and professionally.', 
    category: 'Growth', grade: "High"
    },
    {id: 5, text: 'You scored moderate in the work growth area. You are somewhat interested in new experiences in your work but may feel stagnant in your current role sometimes. You have some experiences, but don’t have a constantly changing environment. Your work may provide some opportunities to grow both personally and professionally, but you don’t see it playing a major role in your own development and growth.', 
    category: 'Growth', grade: "Moderate"
    },
    {id: 6, text: 'You scored low in the work growth area. You may feel stagnant or bored in your current role. You don’t often get experiences that expand your horizons. You usually don’t see your work as something that contributes to your own development or growth as a person.', 
    category: 'Growth', grade: "Low"
    },
    {id: 7, text: 'You scored high in the work competence area. You likely have a high level of skill in the work you do. Your job responsibilities don’t overwhelm you and you feel in control of your work environment. You’re able to use the complex set of tools required to skillfully get a job done.', 
    category: 'Competence', grade: "High"
    },
    {id: 8, text: 'You scored moderate in the work competence area. You are still getting comfortable with the work you’re doing and have gained confidence in your skills. You sometimes feel overwhelmed by your job and have some trouble managing your day-to-day tasks. You are still learning all the complex nuances of your work and how they can be used to help you work better.', 
    category: 'Competence', grade: "Moderate"
    },
    {id: 9, text: 'You scored low in the work competence area. While this doesn’t mean that you are bad at your job or incompetent, it means that you feel less in control of your work situation. Your job demands may be more than you feel like you can handle. On most days, you struggle to manage day-to-day tasks and it feels like it’s hard to improve your situation.', 
    category: 'Competence', grade: "Low"
    },
    {id: 10, text: 'You scored high in the work positive relationships area. You have an environment at work that makes you feel safe and you contribute to those feelings for your coworkers. You have a trusting relationship with the people you work with where there is mutual care for one another’s welfare. Your workplace fosters a sense of community and you enjoy being around the people you work with.', 
    category: 'Relationships', grade: "High"
    },
    {id: 11, text: 'You scored moderate in the work positive relationships area.Your work relationships are neither warm nor cold. You have a somewhat blasé relationship with the people you work with. You may find it somewhat difficult to trust people at work, but don’t see your coworkers as inherently untrustworthy people.', 
    category: 'Relationships', grade: "Moderate"
    },
    {id: 12, text: 'You scored low in the work positive relationships area. Your work environment involves few close relationships with the people you work with. It’s hard to trust the people you interact with on the job. You find it difficult in this work environment to be warm or concerned about the people around you. At this point in your job, you probably aren’t interested in making compromises to sustain any social ties to the people you work with.', 
    category: 'Relationships', grade: "Low"
    },
    {id: 13, text: 'You scored high in the work purpose area. You feel like you have goals in your work that are moving you towards. Your work so far has gone in a particular direction that you’ve helped shape and mold. You have plans  for your career and you act on them accordingly. You may feel like your work is important in the grand scheme of things and that it has meaning.', 
    category: 'Purpose', grade: "High"
    },
    {id: 14, text: 'You scored moderate in the work purpose area. You have some sense of direction in your work, but the goals you’re moving towards are not always clear or self-directed. You may feel indifferent about the impact your work has in the greater scheme of things.', 
    category: 'Purpose', grade: "Moderate"
    },
    {id: 15, text: 'You scored low in the work purpose area. You lack a sense of direction at work and do not have clear goals. You rarely think about your career path and are not intentional about carrying out your plans at work. Likely, you don’t feel like your work has much impact in the greater scheme of things.', 
    category: 'Purpose', grade: "Low"
    },
    {id: 16, text: 'You scored high in the work self-acceptance area. You generally have a positive attitude about yourself and your work. You feel pride when you look back at what you’ve accomplished so far in your work journey. You get a lot out of your work and like who you get to be in your work role.', 
    category: 'Self-Acceptance', grade: "High"
    },
    {id: 17, text: 'You scored moderate in the work self-acceptance area. You have mixed feelings about how you show up at work. You don’t necessarily find pride in your work, but you also don’t feel bad about your work. You have neither positive nor negative views of your work and who you get to be in your work role.', 
    category: 'Self-Acceptance', grade: "Moderate"
    },
    {id: 18, text: 'You scored low in the work self-acceptance area. You may feel dissatisfied with your work and where you are in your career. You are sometimes troubled by certain aspects of your job. You do not always feel positive about your work situation and sometimes wish you were in a different place.', 
    category: 'Self-Acceptance', grade: "Low"
    }
]

export default resultList;