// In this React component, a footer is being created for a web page. The component imports necessary CSS styles, images, and icons. 
// The Footer component returns a JSX structure, containing various elements that make up the footer. 

// Import the required CSS file for the footer styles
import "./footer.css";

// Import images and icons to be used in the footer
import logo from "./logo.png";
import email from "./icons/email.png";
import facebook from "./icons/facebook.png";
import instagram from "./icons/instagram.png";
import linkedin from "./icons/linkedin.png";
import twitter from "./icons/twitter.png";

// Define the Footer functional component
const Footer = () => {
  return (
    // Create a container for the footer with the class "footer"
    <div className="footer">
      <div className="left-side">
        <img src={logo} alt="logo" className="footer-logo" />
        <div className="footer-text">All rights reserved. © Humanalysts</div>
      </div>
      <div className="right-side">
        <div className="footer-text">CONTACT@HUMANALYSTS.COM</div>
        <div className="social-icons">
          <a href="https://www.instagram.com/humanalysts/" target="_blank" rel="noreferrer">
            <img src={instagram} alt="Instagram logo" className="icon"/>
          </a>
          <a href="https://www.linkedin.com/company/humanalysts/" target="_blank" rel="noreferrer">
            <img src={linkedin} alt="LinkedIn logo" className="icon"/>
          </a>
          <a href="https://twitter.com/humanalysts" target="_blank" rel="noreferrer">
            <img src={twitter} alt="Twitter logo" className="icon"/>
          </a>
          <a href="https://www.facebook.com/humanalysts" target="_blank" rel="noreferrer">
            <img src={facebook} alt="Facebook logo" className="icon"/>
          </a>
          <a href="mailto:contact@humanalysts.com" target="_blank" rel="noreferrer">
            <img src={email} alt="Email logo" className="icon"/>
          </a>
      </div>
      </div>
    </div>
  );
};

// Export the Footer component to be used in other parts of the application
export default Footer;

