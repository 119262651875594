import axios from 'axios';

const INSTAGRAM_API_BASE_URL = 'https://graph.instagram.com';
const ACCESS_TOKEN = 'your_access_token_here'; // Replace this with your access token

export const fetchInstagramPosts = async () => {
  try {
    const response = await axios.get(
      `${INSTAGRAM_API_BASE_URL}/me/media?fields=id,caption,media_type,media_url,thumbnail_url,permalink&access_token=${ACCESS_TOKEN}`
    );

    const data = response.data.data;

    const formattedPosts = data.map((post) => ({
      id: post.id,
      caption: post.caption,
      mediaType: post.media_type,
      mediaUrl: post.media_type === 'VIDEO' ? post.thumbnail_url : post.media_url,
      permalink: post.permalink
    }));

    return formattedPosts;
  } catch (error) {
    console.error('Error fetching Instagram posts:', error);
    return [];
  }
};
