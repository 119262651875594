// This is a React component for rendering a questionnaire. The component displays a list of questions with options for users to respond. 
// It includes a progress bar to indicate the percentage of questions answered, as well as next and back buttons to navigate through the questions. 
// When all questions have been answered, users can view the results.

import "./questions.css";
import React, { useState } from "react";
import questionList from "./questionList";
import logoImg from "./barLogo2.png";

// Define the options and option values for the questionnaire
const options = [
  "Strongly Disagree",
  "Disagree",
  "Neither",
  "Agree",
  "Strongly Agree",
];

const optionValues = [0, 25, 50, 75, 100];

// Initialize categoryTotals object for storing category scores
const categoryTotals = {};
questionList.forEach((question) => {
  if (!categoryTotals[question.category]) {
    categoryTotals[question.category] = 0;
  }
});

// Define the Questions component
const Questions = (props) => {
  const [selectedOption, setSelectedOption] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');
  const [isFixed, ] = useState(false);

  // Function to handle option selection and update category scores
  const handleClick = (questionId, option) => {
    setSelectedOption({ ...selectedOption, [questionId]: option });
    const category = questionList.find((q) => q.id === questionId).category;
    categoryTotals[category] += optionValues[options.indexOf(option)];
  };

  // Function to handle smooth scrolling when navigating through questions
  const handleScroll = () => {
    const homeContainer = document.querySelector(".homeContainer");
    const targetY = homeContainer.offsetTop - 50;
    window.scroll({ top: targetY, behavior: "smooth" });
  };

  // Add event listener for scroll to handle progress bar fixed positioning
  const progressBar = document.querySelector('.progress-bar-container');

  window.addEventListener('scroll', function() {
    if (window.pageYOffset >= progressBar.offsetTop) {
      progressBar.classList.add('fixed');
    } 
    if (window.pageYOffset < 500) {
      progressBar.classList.remove('fixed');
    }
  });  

  // Functions to handle next and back button clicks
  const handleBack = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
      setErrorMessage('');
      handleScroll();
    }
  };

  const handleNext = () => {
    // Check if all questions on the current page have been answered
    if (Object.keys(selectedOption).length >= currentQuestions.length * (currentPage + 1)) {
      setCurrentPage(currentPage + 1);
      setErrorMessage('');
      handleScroll();
    } else {
      console.log(currentPage)
      console.log(Object.keys(selectedOption).length)
      console.log(currentQuestions.length)
      setErrorMessage('Please answer all questions.');
    }
  };

  // Function to handle view results button click
  const handleViewResults = () => {
    const categoryAverages = {};
    Object.keys(categoryTotals).forEach((category) => {
      categoryAverages[category] = Math.min(categoryTotals[category] / 3, 100);
    });
    props.onViewResults(categoryAverages);
  };

  // Slice the questionList array to get questions for the current page
  const currentQuestions = questionList.slice(
    currentPage * 6,
    currentPage * 6 + 6
  );

  // Calculate progress percentage
  const progress = (Object.keys(selectedOption).length / questionList.length) * 100;

  // Return the JSX for rendering the questionnaire
  return (
    <div>
        <div className={`progress-bar-container ${isFixed ? "fixed" : ""}`}>
          <div className="progress-bar" style={{ width: `${progress + 1}%` }} />
          <div className="progress-percentage" style={{ left: `${progress}%` }}>{`${progress.toFixed(0)}%`}</div>
          <img
            src={logoImg}
            alt="logo"
            className="progress-logo"
            style={{ left: `${progress}%` }}
          />
        </div>

      {currentQuestions.map((question) => (
        <div className="survey-body">
          <div className="question-field" key={question.id}>
            <div className="question-text">{question.text}</div>
          </div>

          <div className="answer-field-with-label">
          <div className="label-left">Strongly Disagree</div>
          <div className="answer-field">
            {options.map((option, index) => (
              <div
                key={index}
                className={`circle-container ${
                  selectedOption[question.id] === option ? "selected" : ""
                }`}
                onClick={() => handleClick(question.id, option)}
              >
                <div className="circle"></div>
                <div className="option-text">{option}</div>
              </div>
            ))}
          </div>
          <div className="label-right">Strongly Agree</div>
        </div>
        </div>
      ))}
        <div className="buttons-container">
          {currentPage > 0 ? (
            <div className="back-button" style={{ marginBottom: errorMessage ? '2.4rem' : '' }}>
              <button onClick={handleBack}>&#8656; &nbsp; Back &nbsp;</button>
            </div>
          ) : null}
          {currentPage < Math.ceil(questionList.length / 6) - 1 ? (
            <div className={`next-button ${errorMessage ? 'error-active' : ''}`}>
              <button onClick={handleNext}>&nbsp; Next &nbsp; &#8658;</button>
              {errorMessage && <div className="error-message">{errorMessage} </div>}
            </div>
          ) : (
            <div className="view-results-button">
              <button onClick={handleViewResults}>View Results</button>
            </div>
          )}
        </div>
    </div>
  );
};

export default Questions;
