import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import MailList from "../../components/mailList/MailList";
import Navbar from "../../components/navbar/Navbar";
import Questions from "../../components/questions/Questions";
import Result from "../../components/result/Result";
import React, { useState } from "react";

import "./home.css";

const Home = () => {
  const [showResult, setShowResult] = useState(false);
  const [results, setResults] = useState(null);
  
  const handleViewResults = (resultsFromQuestions) => {
    setShowResult(true);
    setResults(resultsFromQuestions);
  };

  return (
    <div>
      <Navbar />
      {!showResult && <Header />}
      <div className="homeContainer">
        {showResult ? (
          <Result className = "resultContainer" results={results} />
        ) : (
          <Questions onViewResults={handleViewResults} />
        )}
        <MailList />
        <Footer />
      </div>
    </div>
  );
};

export default Home;
