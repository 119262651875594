import React, { useRef, useEffect, useState } from "react";
import Chart from "chart.js/auto";
import "./result.css";
import ChartDataLabels from "chartjs-plugin-datalabels";
import resultList from "./resultList";
import suggestList from "./suggestList";
import DemographicSurvey from "../demographic/Demographic";

const getOverallScoreExplaination = (grade) => {
  if (grade === "High") {
    return "You’ve scored high on thriving at work, which indicates that you can reach your full potential in your workplace. You have a strong sense of support from your colleagues, ample opportunity to learn and build your skills and talents, independence to work the way you want to work, appreciation of  the person you are at work, confidence in your abilities, and purpose that your work is meaningful.";
  } else if (grade === "Moderate") {
    return "You’ve scored moderate on thriving at work, which indicates that you can moderately reach your full potential in your workplace. You experience some of 6 elements that make up thriving at work, but there is room to keep growing so that you can reach your maximum potential at work.";
  } else if (grade === "Low") {
    return "You’ve scored low on thriving at work, which indicates that there is a lot of opportunity to help you reach your full potential in your workplace. Ask yourself, which area would you like to focus and gain support from your colleagues, ample opportunity to learn and build your skills and talents, independence to work the way you want to work, appreciation of  the person you are at work, confidence in your abilities, and purpose that your work is meaningful.";
  }
};

const descriptions = {
  "Autonomy": "When a person has the freedom and control to complete their job in the way they like to work. An example is an employee feeling independent about making decisions that may impact their work.",
  "Self-Acceptance": "In order to professionally thrive, a person holds awareness and accepts themselves in the context of their work role. An example of self-acceptance is when a person likes the person they are when they are at work.",
  "Growth": "En route to individual thriving, a person would feel their workplace gives them the opportunity to grow their skills and talents. This person’s new skills and talents would also grow in their job and career. An example of work growth is companies offering many courses, resources, and time to always encourage learning.",
  "Relationships": "To reach their full potential at work, individuals have a strong sense of connection, support, and positive interactions with their coworkers. Essentially, work relationships is the level of connection and positivity a person feels about their people they work with. An example is feeling comfortable with people at work.",
  "Competence": "Another element of thriving at work is work competence. A person has work competence when they see themself having the necessary skills to navigate through work situations well. An example is feeling confident in one’s ability to reach work goals.",
  "Purpose": "Work purpose is when a person finds meaning and purpose in their work. An example of this is knowing that your work will have a positive impact on other people’s lives."
};


const ResultChart = ({ results }) => {
  useEffect(() => {
    window.scrollTo({ top: 105, behavior: "smooth" });
  }, []);

  const [showSurvey, setShowSurvey] = useState(false);

  useEffect(() => {
    if (!showSurvey) {
      window.scrollTo(0, 105);
    }
  }, [showSurvey]);

  const roundedResult = Object.fromEntries(
    Object.entries(results).map(([key, value]) => [key, Math.round(value)])
  );
  const chartRef = useRef(null);
  const data = Object.values(roundedResult);

  useEffect(() => {
    if (!showSurvey) {
      let chart;

      const marksCanvas = chartRef.current;

      if (chart) {
        chart.destroy();
      }
      Chart.register(ChartDataLabels);

      Chart.defaults.font.family = "muli, sans-serif";
      Chart.defaults.font.size = 12;
      Chart.defaults.color = "#38465E";
      Chart.defaults.set("plugins.datalabels", {
        color: "#FCD367",
      });

      const marksData = {
        labels: Object.keys(results),
        datasets: [
          {
            label: "Result",
            borderColor: "#FBB702",
            borderWidth: 3,
            pointRadius: 0,
            fill: false,
            data,
          },
        ],
      };

      const chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        // aspectRatio: 1,
        borderColor: "#227788",
        plugins: {
          legend: {
            display: false,
          },
          datalabels: {
            anchor: "end",
            align: "end",
            offset: 4,
            font: (context) => {
              const chart = context.chart;
              const canvasWidth = chart.width;
      
              let fontSize = 14;
              if (canvasWidth <= 300) {
                fontSize = 10;
              } else if (canvasWidth <= 800) {
                fontSize = 12;
              } else {
                fontSize = 14;
              }
      
              return {
                size: fontSize,
                weight: "bold",
              };
            },
          },
        },
        scales: {
          r: {
            pointLabels: {
              padding: 30,
              font: (context) => {
                const chart = context.chart;
                const canvasWidth = chart.width;
        
                let fontSize = 23;
                if (canvasWidth <= 700) {
                  fontSize = 15;
                } else if (canvasWidth <= 800) {
                  fontSize = 20;
                } else {
                  fontSize = 23;
                }
        
                return {
                  size: fontSize,
                  weight: "bold",
                };
              },
            },
            angleLines: {
              display: false,
            },
            ticks: {
              backdropColor: "transparent",
              stepSize: 25,
              color: "#FCD367",
              z: 1,
              size: 20,
              display: false,
            },
            min: 0,
            max: 100,
            backgroundColor: "#0D1273",
            grid: {
              color: "white",
              tickMarkLength: 10,
            },
          },
        },
      };

      chart = new Chart(marksCanvas, {
        type: "radar",
        data: marksData,
        options: chartOptions,
      });

      return () => {
        if (chart) {
          chart.destroy();
        }
      };
    }
  }, [data, results, showSurvey]);

  const getGrade = (score) => {
    if (score >= 0 && score <= 33) {
      return "Low";
    } else if (score >= 34 && score <= 66) {
      return "Moderate";
    } else if (score >= 67 && score <= 100) {
      return "High";
    }
  };

  const [currentTextFirst, setCurrentTextFirst] = useState("");
  const [currentSuggestion, setCurrentSuggestion] = useState([]);

  const lists = [
    {
      id: 1,
      title: "Autonomy",
      score: getGrade(Math.round(results["Work Autonomy"])),
    },
    {
      id: 2,
      title: "Growth",
      score: getGrade(Math.round(results["Work Growth"])),
    },
    {
      id: 3,
      title: "Competence",
      score: getGrade(Math.round(results["Work Competence"])),
    },
    {
      id: 4,
      title: "Relationships",
      score: getGrade(Math.round(results["Work Relationships"])),
    },
    {
      id: 5,
      title: "Purpose",
      score: getGrade(Math.round(results["Work Purpose"])),
    },
    {
      id: 6,
      title: "Self-Acceptance",
      score: getGrade(Math.round(results["Work Self-Acceptance"])),
    },
  ];

  const [selected, setSelected] = useState(0);
  const [currentName, setCurrentName] = useState();
  const [selectedCategory, setSelectedCategory] = useState(null);

  const firstP = resultList.filter(
    (obj) => obj.category === "Autonomy" && obj.grade === lists[0].score
  )[0].text;
  const firstSuggest = suggestList.filter(
    (obj) => obj.category === "Autonomy"
  )[0].suggestion;

  useEffect(() => {
    setCurrentTextFirst(firstP);
  }, [firstP]);
  useEffect(() => {
    setCurrentName("Work Autonomy");
  }, []);
  useEffect(() => {
    setCurrentSuggestion(firstSuggest);
  }, [firstSuggest]);

  const handleColor = (event, row) => {
    event.preventDefault();
    setSelected(row.id);

    const categoryMapping = {
      1: { key: "Autonomy", label: "Work Autonomy" },
      2: { key: "Growth", label: "Work Growth" },
      3: { key: "Competence", label: "Work Competence" },
      4: { key: "Relationships", label: "Work Relationships" },
      5: { key: "Purpose", label: "Work Purpose" },
      6: { key: "Self-Acceptance", label: "Work Self-Acceptance" },
    };

    const category = categoryMapping[row.id];

    if (category) {
      setCurrentTextFirst(
        resultList.filter(
          (obj) => obj.category === category.key && obj.grade === row.score
        )[0].text
      );
      setCurrentName(category.label);
      setCurrentSuggestion(
        suggestList.filter((obj) => obj.category === category.key)[0].suggestion
      );
      setSelectedCategory(category.key);
    }
  };

  useEffect(() => {
    setSelected(1);
  }, []);

  function getColumnStyle(grade) {
    if (grade === "High") {
      return { color: "#0D1273" };
    } else if (grade === "Low") {
      return { color: "#227788" };
    } else {
      return {};
    }
  }

  return (
    <>
      {!showSurvey ? (
        // Your result page content goes here
        <div>
          <div className="result-container">
            <div className="resultTitle">
              <h1>Well done,</h1>
              <p>You are one step closer to Thriving At Work!</p>
            </div>
            <div className="chart-container">
              <canvas className="marksChart" ref={chartRef}></canvas>
            </div>
            <p className="survey-link" onClick={() => setShowSurvey(true)}>
            </p>
            <div className="score-container">
              <div className="row">
                <div
                  className="col"
                  style={getColumnStyle(
                    getGrade(Math.round(results["Work Autonomy"]))
                  )}
                >
                  <p className="skill">Autonomy</p>
                  <p className="score">
                    {Math.round(results["Work Autonomy"])}
                  </p>
                  <p className="grade">
                    {getGrade(Math.round(results["Work Autonomy"]))}
                  </p>
                </div>
                <div
                  className="col"
                  style={getColumnStyle(
                    getGrade(Math.round(results["Work Self-Acceptance"]))
                  )}
                >
                  <p className="skill">Self-Acceptance</p>
                  <p className="score">
                    {Math.round(results["Work Self-Acceptance"])}
                  </p>
                  <p className="grade">
                    {getGrade(Math.round(results["Work Self-Acceptance"]))}
                  </p>
                </div>
                <div
                  className="col"
                  style={getColumnStyle(
                    getGrade(Math.round(results["Work Growth"]))
                  )}
                >
                  <p className="skill">Growth</p>
                  <p className="score">{Math.round(results["Work Growth"])}</p>
                  <p className="grade">
                    {getGrade(Math.round(results["Work Growth"]))}
                  </p>
                </div>
                <div
                  className="col overall-score"
                  style={getColumnStyle(
                    getGrade(
                      Math.round(
                        Object.values(results).reduce(
                          (acc, val) => acc + val,
                          0
                        ) / Object.values(results).length
                      )
                    )
                  )}
                >
                  <p className="skill">Overall</p>
                  <p className="score">
                    {Math.round(
                      Object.values(results).reduce(
                        (acc, val) => acc + val,
                        0
                      ) / Object.values(results).length
                    )}
                  </p>
                  <p className="grade">
                    {getGrade(
                      Math.round(
                        Object.values(results).reduce(
                          (acc, val) => acc + val,
                          0
                        ) / Object.values(results).length
                      )
                    )}
                  </p>
                </div>
                <div
                  className="col"
                  style={getColumnStyle(
                    getGrade(Math.round(results["Work Relationships"]))
                  )}
                >
                  <p className="skill">Relationships</p>
                  <p className="score">
                    {Math.round(results["Work Relationships"])}
                  </p>
                  <p className="grade">
                    {getGrade(Math.round(results["Work Relationships"]))}
                  </p>
                </div>
                <div
                  className="col"
                  style={getColumnStyle(
                    getGrade(Math.round(results["Work Competence"]))
                  )}
                >
                  <p className="skill">Competence</p>
                  <p className="score">
                    {Math.round(results["Work Competence"])}
                  </p>
                  <p className="grade">
                    {getGrade(Math.round(results["Work Competence"]))}
                  </p>
                </div>
                <div
                  className="col"
                  style={getColumnStyle(
                    getGrade(Math.round(results["Work Purpose"]))
                  )}
                >
                  <p className="skill">Purpose</p>
                  <p className="score">{Math.round(results["Work Purpose"])}</p>
                  <p className="grade">
                    {getGrade(Math.round(results["Work Purpose"]))}
                  </p>
                </div>
              </div>
            </div>
          <div className="explain-container">
              <div className="explain">
              <div className="explainOverall">
              <span className="toDevelop"> Overall Performance: </span>
              <p className="description">{getOverallScoreExplaination(getGrade(
                Math.round(
                  Object.values(results).reduce(
                    (acc, val) => acc + val,
                    0
                  ) / Object.values(results).length
                )
              ))}</p>
            </div>
              <div className="category">
                {lists.map((list) => (
                  <button
                    className={`categoryButton ${list.id === selected ? 'selected' : ''}`}
                    key={list.id}
                    onClick={(event) => handleColor(event, list)}
                  >
                    <p>{list.title}</p>
                  </button>
                ))}
              </div>

                <p className="description">{descriptions[selectedCategory]}</p>
                <p className="description">{currentTextFirst}</p>

                <p style={{ fontSize: "30px", color: "#38465e" }}>
                  <span className="toDevelop">
                    You Can Develop Your {currentName} Through: <br />
                    <br />
                  </span>
                </p>

                <ul>
                  {currentSuggestion.map((item) => (
                    <li className="suggestionList">{item}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <p className="survey-link" onClick={() => setShowSurvey(true)}>
            <span> ⸢ </span> &nbsp; Click Here to Take Our Demographic Survey ! &nbsp; <span> ⸥ </span>
          </p>
        </div>
      ) : (
        <DemographicSurvey onBackClick={() => setShowSurvey(false)} />
      )}
    </>
  );
};

export default ResultChart;
