import React, { useEffect, useState } from 'react';
import './latest.css';
import latestBg from './latest-bg.jpeg';
import { fetchInstagramPosts } from './instagramService';

const Latest = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const fetchPosts = async () => {
      const fetchedPosts = await fetchInstagramPosts();
      setPosts(fetchedPosts);
    };
    fetchPosts();
  }, []);

  return (
    <div className="latest-container">
      <div className="latest-header" style={{ backgroundImage: `url(${latestBg})` }}>
        <h1>Latest</h1>
      </div>
      <div className="posts-container">
        {posts.map((post, index) => (
          <div key={index} className="post">
            <div className="post-image">
              <img src={post.imageUrl} alt={post.caption} />
            </div>
            <div className="post-content">
              {post.caption}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Latest;
