// The Navbar component is a reusable, functional React component that displays a navigation bar with a logo, navigation links, and a login button. 
// The navigation links change their appearance based on the user's current active section. 
// When viewed on a mobile device, the navigation links are displayed in a mobile menu.

// Import necessary modules and styles
import "./navbar.css";
import { Link } from "react-router-dom";
import { useState, useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import LoginForm from "../login/FirebaseLogin";
import { CSSTransition } from "react-transition-group";

// Navbar component definition
const Navbar = () => {

  // Define local state variables
  const { user } = useContext(AuthContext);
  const [activeSection, setActiveSection] = useState("home");
  const [isLoginFormOpen, setIsLoginFormOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  // Event handlers
  const toggleLoginForm = () => {
    setIsLoginFormOpen(!isLoginFormOpen);
  };

  const handleSectionChange = (section) => {
    setActiveSection(section);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <div className="navbar">
      <div className="navContainer">
        <div className="navItems">
          <Link to="/" style={{ color: "inherit", textDecoration: "none" }}>
            <img
              src="https://lirp.cdn-website.com/911f1641/dms3rep/multi/opt/Humanalysts+Colour+Logo-1920w.png"
              alt="Logo"
              className="logo"
              height={42}
              width={"auto"}
            />
          </Link>
        </div>

        <div className="navItems desktop-menu">
          <Link
            to="/"
            style={{ textDecoration: "none", padding: "20px", marginLeft: "0" }}
            onClick={() => handleSectionChange("home")}
          >
            <span
              className={`sections ${activeSection === "home" ? "active" : ""}`}
            >
              HOME
            </span>
          </Link>
          <a
            href="https://www.humanalysts.com/the-latest"
            rel="noopener noreferrer"
            style={{ textDecoration: "none", padding: "20px" }}
            onClick={() => handleSectionChange("latest")}
          >
            <span
              className={`sections ${
                activeSection === "latest" ? "active" : ""
              }`}
            >
              THE LATEST
            </span>
          </a>
          {/* <Link
            to="/"
            style={{ textDecoration: "none", padding: "20px" }}
            onClick={() => handleSectionChange("dashboard")}
          >
            <span
              className={`sections ${
                activeSection === "dashboard" ? "active" : ""
              }`}
            >
              Dashboard
            </span>
          </Link> */}
          <a
            href="https://www.humanalysts.com/contact"
            style={{
              textDecoration: "none",
              padding: "20px",
              marginRight: "50px",
            }}
            onClick={() => handleSectionChange("contact")}
          >
            <span
              className={`sections ${
                activeSection === "contact" ? "active" : ""
              }`}
            >
              CONTACT
            </span>
          </a>
        </div>

        <div className="navItems desktop-menu">
          {user ? (
            user.username
          ) : (
            <div className="navItems">
              <button className="navButton" onClick={toggleLoginForm}>
                LOGIN
              </button>
            </div>
          )}
        </div>

        <div className="navItems mobile-menu">
          <button className="navButton" onClick={toggleMobileMenu}>
            ☰
          </button>
        </div>
      </div>

      {isMobileMenuOpen && (
        <div className={`mobile-menu-items ${isMobileMenuOpen ? "open" : ""}`}>
          <Link
            to="/"
            style={{ color: "inherit", textDecoration: "none" }}
            onClick={() => {
              handleSectionChange("home");
              toggleMobileMenu();
            }}
          >
            <span
              className={`sections ${activeSection === "home" ? "active" : ""}`}
            >
              HOME
            </span>
          </Link>
          <a
            href="https://www.humanalysts.com/the-latest"
            rel="noopener noreferrer"
            style={{ color: "inherit", textDecoration: "none" }}
            onClick={() => {
              handleSectionChange("latest");
              toggleMobileMenu();
            }}
          >
            <span
              className={`sections ${
                activeSection === "latest" ? "active" : ""
              }`}
            >
              THE LATEST
            </span>
          </a>
          <a
            href="https://www.humanalysts.com/contact"
            style={{ color: "inherit", textDecoration: "none" }}
            onClick={() => {
              handleSectionChange("contact");
              toggleMobileMenu();
            }}
          >
            <span
              className={`sections ${
                activeSection === "contact" ? "active" : ""
              }`}
            >
              CONTACT
            </span>
          </a>
          {!user && (
            <button
              className="navButton"
              onClick={() => {
                toggleLoginForm();
                toggleMobileMenu();
              }}
            >
              LOGIN
            </button>
          )}
        </div>
      )}

      <CSSTransition
        in={isLoginFormOpen}
        timeout={400}
        classNames="login-form"
        unmountOnExit
      >
        <LoginForm isOpen={isLoginFormOpen} onClose={toggleLoginForm} />
      </CSSTransition>
    </div>
  );
};

export default Navbar;
