import React, { useState, useEffect } from "react";
import ExpandableSection from "./ExpandableSection";
import "./demographic.css";

const DemographicSurvey = ({ onBackClick }) => {
  const [gender, setGender] = useState(null);
  const [lgbtqia, setLgbtqia] = useState(null);
  const [disability, setDisability] = useState(null);
  const [showPopup, setShowPopup] = useState(false);

  const handleSubmit = () => {
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
    onBackClick();
  };

  useEffect(() => {
    window.scrollTo({ top: 100, behavior: "smooth" });
  }, []);

  return (
    <>
      <div className="surveyHeader">
        <div className="surveyHeaderContainer">
          <h1 className="headerTitle">Demographic Survey</h1>
          <p className="headerDesc2">
            Please tell us a little bit about yourself!
            <br />
            <br />
            The demographic information provided will be used for analysis
            purpose, your answers will not affects the survey result.
            <br />
            <br />
            <span>
              {" "}
              Please keep in mind that you only need to share what you feel
              comfortable sharing!{" "}
            </span>
          </p>
        </div>
      </div>

      <div className="sectionContainer">
        <button className="survey-back-button" onClick={onBackClick}>
          ⇦ &nbsp; Back to Results
        </button>
        <ExpandableSection title="Personal Information" defaultOpen={true}>
          <div className="form-group">
            <label htmlFor="dateOfBirth">What is your date of birth?</label>
            <br />
            <input
              type="date"
              id="dateOfBirth"
              name="dateOfBirth"
              className="form-control"
              placeholder="MM/DD/YYYY"
            />
          </div>
          <div className="form-group">
            <label htmlFor="gender">
              What best represents your gender identity?
            </label>
            <div className="form-control">
              <input
                type="radio"
                id="male"
                name="gender"
                value="Male"
                onChange={() => setGender("Male")}
              />
              <label htmlFor="male">Male</label>
              <input
                type="radio"
                id="female"
                name="gender"
                value="Female"
                onChange={() => setGender("Female")}
              />
              <label htmlFor="female">Female</label>
              <input
                type="radio"
                id="genderNonConforming"
                name="gender"
                value="Gender Non-Conforming"
                onChange={() => setGender("Gender Non-Conforming")}
              />
              <label htmlFor="genderNonConforming">Gender Non-Conforming</label>
            </div>
          </div>
          {gender === "Gender Non-Conforming" && (
            <div className="form-group">
              <label htmlFor="genderIdentity">
                You indicated that you identify as gender non-conforming. Which
                of the following best represents your gender identity?
              </label>
              <div className="form-control">
                <input
                  type="radio"
                  id="transgenderMale"
                  name="genderIdentity"
                  value="Transgender Male"
                />
                <label htmlFor="transgenderMale">Transgender Male</label>
                <br />
                <input
                  type="radio"
                  id="transgenderFemale"
                  name="genderIdentity"
                  value="Transgender Female"
                />
                <label htmlFor="transgenderFemale">Transgender Female</label>
                <br />
                <input
                  type="radio"
                  id="genderNeutral"
                  name="genderIdentity"
                  value="Gender Neutral"
                />
                <label htmlFor="genderNeutral">Gender Neutral</label>
                <br />
                <input
                  type="radio"
                  id="genderNonBinary"
                  name="genderIdentity"
                  value="Gender Non-Binary"
                />
                <label htmlFor="genderNonBinary">Gender Non-Binary</label>
                <br />
                <input
                  type="radio"
                  id="intersex"
                  name="genderIdentity"
                  value="Intersex"
                />
                <label htmlFor="intersex">Intersex</label>
                <br />
                <input
                  type="radio"
                  id="otherGenderIdentity"
                  name="genderIdentity"
                  value="Other"
                />
                <label htmlFor="otherGenderIdentity">
                  Other (please specify)
                </label>
              </div>
            </div>
          )}
          <div className="form-group">
            <label htmlFor="race">
              What most closely represents your race? <br />
              <small>
                *Note: the following are based on the United States EEOC and
                Census categories. We recognize that they don't adequately meet
                the needs of our diverse communities. You'll have an opportunity
                to self-identify in the section below.*
              </small>
            </label>
            <div className="form-control">
              {/* Add all the checkboxes for races */}
              {[
                "White or Caucasian (not Hispanic or Latino)",
                "Black or African American",
                "Hispanic or Latino",
                "Asian or Asian American",
                "American Indian or Alaska Native",
                "Native Hawaiian or other Pacific Islander",
                "Mixed Race",
              ].map((race, index) => (
                <div key={index}>
                  <input
                    type="checkbox"
                    id={`race${index}`}
                    name="race"
                    value={race}
                  />
                  <label htmlFor={`race${index}`}>{race}</label>
                </div>
              ))}
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="lgbtqia">
              Do you identify as LGBTQIA (Lesbian, Gay, Bisexual,
              Queer/Questioning, and Asexual/Aromantic)?{" "}
            </label>
            <div className="form-control">
              <input
                type="radio"
                id="yesLgbtqia"
                name="lgbtqia"
                value="Yes, I identify as LGBTQIA"
                onChange={() => setLgbtqia("Yes, I identify as LGBTQIA")}
              />
              <label htmlFor="yesLgbtqia">Yes, I identify as LGBTQIA</label>
              <input
                type="radio"
                id="noLgbtqia"
                name="lgbtqia"
                value="No, I do not identify as LGBTQIA"
                onChange={() => setLgbtqia("No, I do not identify as LGBTQIA")}
              />
              <label htmlFor="noLgbtqia">
                No, I do not identify as LGBTQIA
              </label>
            </div>
          </div>
          {lgbtqia === "Yes, I identify as LGBTQIA" && (
            <div className="form-group">
              <label htmlFor="lgbtqia-followup">
                You indicated that you identify as LGBQIA. Which of the
                following best represents your sexual orientation? Please note
                that this information will not be shared in any reporting back
                to your team or organization. Responding to this question helps
                us advance our research on how each unique group is impacted by
                workplace climate.
              </label>
              <div className="form-control">
                <input
                  type="radio"
                  id="lesbian"
                  name="lgbtqia-followup"
                  value="Lesbian"
                />
                <label htmlFor="lesbian"> Lesbian </label>
                <br />
                <input
                  type="radio"
                  id="gay"
                  name="lgbtqia-followup"
                  value="Gay"
                />
                <label htmlFor="gay"> Gay </label>
                <br />
                <input
                  type="radio"
                  id="bisexual"
                  name="lgbtqia-followup"
                  value="Bisexual"
                />
                <label htmlFor="bisexual"> Bisexual </label>
                <br />
                <input
                  type="radio"
                  id="queer"
                  name="lgbtqia-followup"
                  value="Queer"
                />
                <label htmlFor="queer"> Queer </label>
                <br />
                <input
                  type="radio"
                  id="questioning"
                  name="lgbtqia-followup"
                  value="Questioning"
                />
                <label htmlFor="questioning"> Questioning </label>
                <br />
                <input
                  type="radio"
                  id="asexual/aromantic"
                  name="lgbtqia-followup"
                  value="Asexual/Aromantic"
                />
                <label htmlFor="asexual/aromantic"> Asexual/Aromantic </label>
              </div>
            </div>
          )}
          <div className="form-group">
            <label htmlFor="religiousAffiliation">
              What is your religious affiliation?
            </label>
            <div className="form-control">
              {/* Add radio buttons for religious affiliation options */}
              <input
                type="radio"
                id="jewish"
                name="religiousAffiliation"
                value="Jewish"
              />
              <label htmlFor="jewish">Jewish</label>
              <br />
              <input
                type="radio"
                id="christian"
                name="religiousAffiliation"
                value="Christian (Catholic, Protestant, Adventist, or other denomination)"
              />
              <label htmlFor="christian">
                Christian (Catholic, Protestant, Adventist, or other
                denomination)
              </label>
              <br />
              <input
                type="radio"
                id="islamic"
                name="religiousAffiliation"
                value="Islamic"
              />
              <label htmlFor="islamic">Islamic</label>
              <br />
              <input
                type="radio"
                id="hindu"
                name="religiousAffiliation"
                value="Hindu"
              />
              <label htmlFor="hindu">Hindu</label>
              <br />
              <input
                type="radio"
                id="buddhism"
                name="religiousAffiliation"
                value="Buddhism"
              />
              <label htmlFor="buddhism">Buddhism</label>
              <br />
              <input
                type="radio"
                id="agnostic"
                name="religiousAffiliation"
                value="Agnostic"
              />
              <label htmlFor="agnostic">Agnostic</label>
              <br />
              <input
                type="radio"
                id="atheist"
                name="religiousAffiliation"
                value="Atheist/ I am not religious"
              />
              <label htmlFor="atheist">Atheist/ I am not religious</label>
              <br />
              <input
                type="radio"
                id="otherReligiousAffiliation"
                name="religiousAffiliation"
                value="Other"
              />
              <label htmlFor="otherReligiousAffiliation">Other</label>
              <input type="text" placeholder="please specify..." />
            </div>
          </div>

          <div className="form-group">
            <label htmlFor="veteran">Are you a veteran?</label>
            <div className="form-control">
              <input type="radio" id="yesVeteran" name="veteran" value="Yes" />
              <label htmlFor="yesVeteran">Yes</label>
              <input type="radio" id="noVeteran" name="veteran" value="No" />
              <label htmlFor="noVeteran">No</label>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="workLocation">
              Where do you primarily conduct your work?
            </label>
            <div className="form-control">
              <input
                type="radio"
                id="office"
                name="workLocation"
                value="In an office, warehouse, or location owned by my organization"
              />
              <label htmlFor="office">
                In an office, warehouse, or location owned by my organization
              </label>
              <br />
              <input
                type="radio"
                id="virtualHome"
                name="workLocation"
                value="Virtually – from home"
              />
              <label htmlFor="virtualHome">Virtually – from home</label>
              <br />
              <input
                type="radio"
                id="virtualCoworking"
                name="workLocation"
                value="Virtually – from a shared coworking space"
              />
              <label htmlFor="virtualCoworking">
                Virtually – from a shared coworking space
              </label>
              <br />
              <input
                type="radio"
                id="hybrid"
                name="workLocation"
                value="Hybrid"
              />
              <label htmlFor="hybrid">Hybrid</label>
              <br />
              <input
                type="radio"
                id="field"
                name="workLocation"
                value="In the field"
              />
              <label htmlFor="field">In the field</label>
              <br />
              <input
                type="radio"
                id="clientOffice"
                name="workLocation"
                value="On-site at a client office"
              />
              <label htmlFor="clientOffice">On-site at a client office</label>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="location">Where do you live?</label>
            <br />
            <input
              type="text"
              id="location"
              name="location"
              className="form-control"
              placeholder="Enter location..."
            />
          </div>

          <div className="form-group">
            <label htmlFor="industry">
              What industry does your job fall under?
            </label>
            <br />
            <select id="industry" name="industry" className="form-control">
              <option value="No Selection">No Selection</option>
              <option value="Accounting">Accounting</option>
              <option value="Administrative">Administrative</option>
              <option value="Aerospace">Aerospace</option>
              <option value="Alcohol, Wine and Spirits">
                Alcohol, Wine and Spirits
              </option>
              <option value="Animation">Animation</option>
              <option value="Apparel and Fashion">Apparel and Fashion</option>
              <option value="Architecture and Planning">
                Architecture and Planning
              </option>
              <option value="Automotive">Automotive</option>
              <option value="Banking">Banking</option>
              <option value="Biotechnology">Biotechnology</option>
              <option value="Broadcast media">Broadcast media</option>
              <option value="Chemicals">Chemicals</option>
              <option value="Civil & Social Organization">
                Civil & Social Organization
              </option>
              <option value="Civil Engineering">Civil Engineering</option>
              <option value="Commercial Real Estate">
                Commercial Real Estate
              </option>
              <option value="Computer and Network Security">
                Computer and Network Security
              </option>
              <option value="Computer Games">Computer Games</option>
              <option value="Computer Hardware">Computer Hardware</option>
              <option value="Computer Networking">Computer Networking</option>
              <option value="Computer Software">Computer Software</option>
              <option value="Construction">Construction</option>
              <option value="Consumer Electronics">Consumer Electronics</option>
              <option value="Consumer Goods">Consumer Goods</option>
              <option value="Consumer Services">Consumer Services</option>
              <option value="Cosmetics">Cosmetics</option>
              <option value="Dairy">Dairy</option>
              <option value="Defense and Space">Defense and Space</option>
              <option value="Design">Design</option>
              <option value="E-learning">E-learning</option>
              <option value="Education">Education</option>
              <option value="Electrical/Electronic Manufacturing">
                Electrical/Electronic Manufacturing
              </option>
              <option value="Entertainment">Entertainment</option>
              <option value="Environmental Services">
                Environmental Services
              </option>
              <option value="Events Services">Events Services</option>
              <option value="Farming">Farming</option>
              <option value="Financial Services">Financial Services</option>
              <option value="Fine Arts">Fine Arts</option>
              <option value="Fishing">Fishing</option>
              <option value="Food and Beverages">Food and Beverages</option>
              <option value="Food Production">Food Production</option>
              <option value="Fundraising">Fundraising</option>
              <option value="Furniture">Furniture</option>
              <option value="Gambling and Casinos">Gambling and Casinos</option>
              <option value="Glass, Ceramics, and Concrete">
                Glass, Ceramics, and Concrete
              </option>
              <option value="Government Administration">
                Government Administration
              </option>
              <option value="Graphic Design">Graphic Design</option>
              <option value="Health, Wellness, and Fitness">
                Health, Wellness, and Fitness
              </option>
              <option value="Healthcare & Hospitals">
                Healthcare & Hospitals
              </option>
              <option value="Higher Education">Higher Education</option>
              <option value="Hospitality">Hospitality</option>
              <option value="Human Resources">Human Resources</option>
              <option value="Human Services">Human Services</option>
              <option value="Information Technology and Services">
                Information Technology and Services
              </option>
              <option value="Insurance">Insurance</option>
              <option value="Investment Banking">Investment Banking</option>
              <option value="Law Enforcement">Law Enforcement</option>
              <option value="Legal Services">Legal Services</option>
              <option value="Leisure, Travel, and Tourism">
                Leisure, Travel, and Tourism
              </option>
              <option value="Libraries">Libraries</option>
              <option value="Logistics and Supply Chain">
                Logistics and Supply Chain
              </option>
              <option value="Machinery">Machinery</option>
              <option value="Management Consulting">
                Management Consulting
              </option>
              <option value="Market Research">Market Research</option>
              <option value="Marketing and Advertising">
                Marketing and Advertising
              </option>
              <option value="Mechanical or Industrial Engineering">
                Mechanical or Industrial Engineering
              </option>
              <option value="Media Production">Media Production</option>
              <option value="Medical Services">Medical Services</option>
              <option value="Military">Military</option>
              <option value="Mining">Mining</option>
              <option value="Music">Music</option>
              <option value="Non-Profit Organization and Management">
                Non-Profit Organization and Management
              </option>
              <option value="Online Media">Online Media</option>
              <option value="Public Policy">Public Policy</option>
              <option value="Public Relations">Public Relations</option>
              <option value="Real Estate">Real Estate</option>
              <option value="Religious Institutions">
                Religious Institutions
              </option>
              <option value="Research">Research</option>
              <option value="Restaurants">Restaurants</option>
              <option value="Retail">Retail</option>
              <option value="Staffing and Recruiting">
                Staffing and Recruiting
              </option>
              <option value="Telecommunications">Telecommunications</option>
              <option value="Transportation">Transportation</option>
              <option value="Veterinary">Veterinary</option>
              <option value="Wireless">Wireless</option>
              <option value="Writing & Editing">Writing & Editing</option>
            </select>
          </div>

          <div className="form-group">
            <label htmlFor="physicalHealth">
              In general, would you say your physical health is poor, fair,
              good, very good, or excellent?
            </label>
            <div className="form-control">
              <input
                type="radio"
                id="poor"
                name="physicalHealth"
                value="Poor"
              />
              <label htmlFor="poor">Poor</label>
              <input
                type="radio"
                id="fair"
                name="physicalHealth"
                value="Fair"
              />
              <label htmlFor="fair">Fair</label>
              <input
                type="radio"
                id="good"
                name="physicalHealth"
                value="Good"
              />
              <label htmlFor="good">Good</label>
              <input
                type="radio"
                id="veryGood"
                name="physicalHealth"
                value="Very good"
              />
              <label htmlFor="veryGood">Very good</label>
              <input
                type="radio"
                id="excellent"
                name="physicalHealth"
                value="Excellent"
              />
              <label htmlFor="excellent">Excellent</label>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="immigrantOrFirstGeneration">
              Do you identify as an immigrant or first generation?
            </label>
            <br />
            <small>
              *Note: Immigrants are born outside the United States and typically
              have at least one foreign-born parent. First-generation immigrants
              are those whose parents were born outside the United States, and
              second-generation immigrants are those whose parents were born in
              the United States or its territories.*
            </small>
            <div className="form-control">
              <input
                type="radio"
                id="yesImmigrantOrFirstGeneration"
                name="immigrantOrFirstGeneration"
                value="Yes"
              />
              <label htmlFor="yesImmigrantOrFirstGeneration">Yes</label>
              <input
                type="radio"
                id="noImmigrantOrFirstGeneration"
                name="immigrantOrFirstGeneration"
                value="No"
              />
              <label htmlFor="noImmigrantOrFirstGeneration">No</label>
            </div>
          </div>
        </ExpandableSection>
        <ExpandableSection title="Disability">
          <div className="form-group">
            <label htmlFor="disability">
              Do you have a visible or invisible disability?
              <br />
              <small>
                *Note: The Americans with Disability Act (ADA) defines a person
                with a disability as a person who has a physical or mental
                impairment that substantially limits one or more major life
                activity. A disability can be a time-limited impairment e.g.,
                pregnancy, or cancer treatment, or longer-term or life-long
                impairment.*
              </small>
            </label>
            <div className="form-control">
              <input
                type="radio"
                id="disabilityYes"
                name="disability"
                value="Yes"
                onChange={() => setDisability("Yes")}
              />
              <label htmlFor="disabilityYes">Yes, I have a disability</label>
              <br />
              <input
                type="radio"
                id="disabilityNo"
                name="disability"
                value="No"
                onChange={() => setDisability("No")}
              />
              <label htmlFor="disabilityNo">
                No, I do not have a disability
              </label>
            </div>
          </div>
          {disability === "Yes" && (
            <div className="form-group">
              <label htmlFor="disabilityType">
                [If yes] Follow up question: You indicated that you have a
                visible or invisible disability. Which of the following best
                represents your disability?
              </label>
              <div className="form-control">
                <input
                  type="checkbox"
                  id="blind"
                  name="disabilityType"
                  value="Blind"
                />
                <label htmlFor="blind">Blind/partially sighted</label>
                <br />
                <input
                  type="checkbox"
                  id="deaf"
                  name="disabilityType"
                  value="Deaf"
                />
                <label htmlFor="deaf">Deaf/hearing impaired</label>
                <br />
                <input
                  type="checkbox"
                  id="wheelchair"
                  name="disabilityType"
                  value="Wheelchair"
                />
                <label htmlFor="wheelchair">
                  Wheelchair use/mobility problems
                </label>
                <br />
                <input
                  type="checkbox"
                  id="dyslexia"
                  name="disabilityType"
                  value="Dyslexia"
                />
                <label htmlFor="dyslexia">Dyslexia</label>
                <br />
                <input
                  type="checkbox"
                  id="autism"
                  name="disabilityType"
                  value="Autism"
                />
                <label htmlFor="autism">Autism</label>
                <br />
                <input
                  type="checkbox"
                  id="chronicPain"
                  name="disabilityType"
                  value="ChronicPain"
                />
                <label htmlFor="chronicPain">
                  Chronic pain/chronic illness
                </label>
                <br />
                <input
                  type="checkbox"
                  id="autoImmune"
                  name="disabilityType"
                  value="AutoImmune"
                />
                <label htmlFor="autoImmune">
                  Auto-immune disorder (e.g. multiple sclerosis, diabetes,
                  rheumatoid arthritis, lupus)
                </label>
                <br />
                <input
                  type="checkbox"
                  id="unseenDisability"
                  name="disabilityType"
                  value="UnseenDisability"
                />
                <label htmlFor="unseenDisability">
                  Unseen disability (e.g. epilepsy, asthma)
                </label>
                <br />
                <input
                  type="checkbox"
                  id="mentalHealth"
                  name="disabilityType"
                  value="MentalHealth"
                />
                <label htmlFor="mentalHealth">
                  Mental health (e.g. anxiety, depression)
                </label>
                <br />
                <input
                  type="checkbox"
                  id="timeLimited"
                  name="disabilityType"
                  value="TimeLimited"
                />
                <label htmlFor="timeLimited">Time-limited impairment</label>
                <br />
                <input
                  type="checkbox"
                  id="otherDisability"
                  name="disabilityType"
                  value="Other"
                />
                <label htmlFor="otherDisability">Other (please specify)</label>
                <input
                  type="text"
                  id="otherDisabilityText"
                  name="otherDisabilityText"
                  placeholder="Please specify..."
                />
              </div>
            </div>
          )}
        </ExpandableSection>
        <ExpandableSection title="Education">
          <div className="form-group">
            <label htmlFor="educationLevel">
              What is the highest level of education you’ve achieved?
            </label>
            <div className="form-control">
              <input
                type="radio"
                id="noSchool"
                name="educationLevel"
                value="Did not attend school"
              />
              <label htmlFor="noSchool">Did not attend school</label>
              <br />
              <input
                type="radio"
                id="gradeSchool"
                name="educationLevel"
                value="Grade School (1st-5th grade)"
              />
              <label htmlFor="gradeSchool">Grade School (1st-5th grade)</label>
              <br />
              <input
                type="radio"
                id="middleSchool"
                name="educationLevel"
                value="Middle School (6th-8th grade)"
              />
              <label htmlFor="middleSchool">
                Middle School (6th-8th grade)
              </label>
              <br />
              <input
                type="radio"
                id="someHighSchool"
                name="educationLevel"
                value="Some High School (9-12th grade)"
              />
              <label htmlFor="someHighSchool">
                Some High School (9-12th grade)
              </label>
              <br />
              <input
                type="radio"
                id="upToSomeHighSchool"
                name="educationLevel"
                value="Up to Some High School"
              />
              <label htmlFor="upToSomeHighSchool">Up to Some High School</label>
              <br />
              <input type="radio" id="ged" name="educationLevel" value="GED" />
              <label htmlFor="ged">GED</label>
              <br />
              <input
                type="radio"
                id="highSchoolDiploma"
                name="educationLevel"
                value="High School Diploma"
              />
              <label htmlFor="highSchoolDiploma">High School Diploma</label>
              <br />
              <input
                type="radio"
                id="someCollegeTradeSchool"
                name="educationLevel"
                value="Some College or Some Trade School"
              />
              <label htmlFor="someCollegeTradeSchool">
                Some College or Some Trade School
              </label>
              <br />
              <input
                type="radio"
                id="tradeSchoolCompletion"
                name="educationLevel"
                value="Trade School Completion"
              />
              <label htmlFor="tradeSchoolCompletion">
                Trade School Completion
              </label>
              <br />
              <input
                type="radio"
                id="bachelorsDegree"
                name="educationLevel"
                value="Bachelors Degree"
              />
              <label htmlFor="bachelorsDegree">Bachelors Degree</label>
              <br />
              <input
                type="radio"
                id="mastersDegree"
                name="educationLevel"
                value="Masters Degree"
              />
              <label htmlFor="mastersDegree">Masters Degree</label>
              <br />
              <input
                type="radio"
                id="doctoralDegree"
                name="educationLevel"
                value="Doctoral Degree or higher"
              />
              <label htmlFor="doctoralDegree">Doctoral Degree or higher</label>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="firstPostsecondary">
              Are you the first in your family to receive a postsecondary
              education?
            </label>
            <br />
            <small>
              *Note: Postsecondary education is defined as any education
              programs after high school, including trades school,
              apprenticeship programs, community college, and four year college
              or university.*
            </small>
            <div className="form-control">
              <input
                type="radio"
                id="noPostsecondary"
                name="firstPostsecondary"
                value="I did not receive a postsecondary education"
              />
              <label htmlFor="noPostsecondary">
                I did not receive a postsecondary education
              </label>
              <br />
              <input
                type="radio"
                id="notFirst"
                name="firstPostsecondary"
                value="No, I’m not first in my family to receive post secondary education"
              />
              <label htmlFor="notFirst">
                No, I’m not first in my family to receive post secondary
                education
              </label>
              <br />
              <input
                type="radio"
                id="firstDegree"
                name="firstPostsecondary"
                value="Yes, I’m first in my family to receive a postsecondary degree"
              />
              <label htmlFor="firstDegree">
                Yes, I’m first in my family to receive a postsecondary degree
              </label>
              <br />
              <input
                type="radio"
                id="firstGraduate"
                name="firstPostsecondary"
                value="Yes, I’m the first in my family to attend a graduate degree"
              />
              <label htmlFor="firstGraduate">
                Yes, I’m the first in my family to attend a graduate degree
              </label>
            </div>
          </div>
        </ExpandableSection>
        <ExpandableSection title="Income">
          <div className="form-group">
            <label htmlFor="incomeRange">
              Approximately, what is your household income range?
            </label>
            <div className="form-control">
              <input
                type="radio"
                id="range1"
                name="incomeRange"
                value="0-25000"
              />
              <label htmlFor="range1">$0 - $25,000</label>
              <br />
              <input
                type="radio"
                id="range2"
                name="incomeRange"
                value="25001-50000"
              />
              <label htmlFor="range2">$25,001 - $50,000</label>
              <br />
              <input
                type="radio"
                id="range3"
                name="incomeRange"
                value="50001-75000"
              />
              <label htmlFor="range3">$50,001 - $75,000</label>
              <br />
              <input
                type="radio"
                id="range4"
                name="incomeRange"
                value="75001-100000"
              />
              <label htmlFor="range4">$75,001 - $100,000</label>
              <br />
              <input
                type="radio"
                id="range5"
                name="incomeRange"
                value="100001-125000"
              />
              <label htmlFor="range5">$100,001 - $125,000</label>
              <br />
              <input
                type="radio"
                id="range6"
                name="incomeRange"
                value="125001-150000"
              />
              <label htmlFor="range6">$125,001 - $150,000</label>
              <br />
              <input
                type="radio"
                id="range7"
                name="incomeRange"
                value="150001-175000"
              />
              <label htmlFor="range7">$150,001 - $175,000</label>
              <br />
              <input
                type="radio"
                id="range8"
                name="incomeRange"
                value="175001-200000"
              />
              <label htmlFor="range8">$175,001 - $200,000</label>
              <br />
              <input
                type="radio"
                id="range9"
                name="incomeRange"
                value="200000+"
              />
              <label htmlFor="range9">Greater than $200,000</label>
            </div>
          </div>
        </ExpandableSection>
        <div className="button-container">
        <button className="submit-button" onClick={handleSubmit}>
        Submit
      </button>
      </div>
      {showPopup && (
        <div className="popup-overlay">
          <div className="popup-content">
            <h2>Thank you for completing the survey!</h2>
            <button className="back-to-results-button" onClick={handleClosePopup}>
              Back to Results
            </button>
          </div>
        </div>
      )}
      </div>
    </>
  );
};

export default DemographicSurvey;
