// In this React component, a styled header is being created for a web page.

// Import the required CSS file for the header styles
import "./header.css";

// Define the Header functional component
const Header = () => {
  return (
    // Create a container for the header with the class "header"
    <div className="header">
      <div className="headerContainer">
        <h1 className="headerTitle">Thriving at Work Test</h1>
        <p className="headerDesc">
          What Is Individual Thriving At Work ?
        </p>

        <p className="headerDesc2">
        When a person reaches their full potential in the workplace, they thrive. How does a person achieve their full potential? Our researchers have found six elements that contribute to an employee’s thriving at work. Please take our 2-3 minute free survey to learn how to thrive in your workplace!
        </p>
      </div>
    </div>
  );
};

export default Header;
